import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

const Header = ({ siteTitle }) => {
  
  const [open, setOpen] = useState(0);

  let openClass = open ? 'block': 'hidden';
  return (
    <header>
      <nav className="flex items-center justify-between flex-wrap bg-gray-800 p-6">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          {/*
          <svg className="fill-current h-8 w-8 mr-2" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z"/>
          </svg>*/}
          <span className="font-semibold text-xl tracking-tight">
          <Link to="/#home">NJannasch.Dev</Link>
          </span>
        </div>
        <div className="block lg:hidden">
          <button onClick={() => setOpen(!open)} className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-400 hover:text-white hover:border-white">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            </svg>
          </button>
        </div>
        <div className={"w-full flex-grow lg:flex lg:items-center lg:w-auto " + openClass}>
          <div className="text-sm lg:flex-grow text-gray-500" onClick={() => setOpen(false)}>
            <Link to="/" className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">Home</Link>
            <a href="/#professional" className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">Experience</a>
            <a href="/#tech" className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">Tech Stack</a>
            <a href="/#projects" className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">Projects</a>
           {/*<Link to="/about" className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">About</Link>
            <Link to="/blog" className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">Blog</Link>
            */}
            <Link to="/imprint" className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">Imprint</Link>
            <Link to="/dataprivacy" className="block mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4">Data Privacy</Link>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
