import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Footer = () => (
    <footer className="bg-gray-100 h-13 p-4">
      <hr/>
      <div className="flex flex-row justify-around text-gray-600 p-4">
        <div>
          Software Engineer for Web, <br/>
          Cloud and IoT.
          <div className="pl-1 mt-1 text-black">
            <a href="https://twitter.com/NilsJann" target="_blank" rel="noreferrer" className="hover:text-blue-400 p-2">
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
        
            <a href="https://stackoverflow.com/users/5033146/nils" target="_blank" rel="noreferrer" className="hover:text-blue-400 p-2">
              <FontAwesomeIcon icon={["fab", "stack-overflow"]} />
            </a>

            <a href="https://github.com/NJannasch" target="_blank" rel="noreferrer" className="hover:text-blue-400 p-2">
              <FontAwesomeIcon icon={["fab", "github"]} />
            </a>
          </div>
        </div>
        <div>
          <Link className="font-semibold" to="#projects">Projects</Link>
          <div>Coming Soon</div>
        </div>
        <div>
          <a href="https://njannasch.github.io/" target="_blank"><span className="font-semibold">Blog</span></a>
        </div>
        <div>
          <span className="font-semibold">Get in touch:</span>
          <div className="pl-1 mt-1 text-black">
            <a href="https://www.linkedin.com/in/njannasch/" target="_blank" rel="noreferrer" className="hover:text-blue-400 p-2">
              <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
            </a>
            <a href="https://www.xing.com/profile/Nils_Jannasch/cv" target="_blank" rel="noreferrer" className="hover:text-blue-400 p-2">
              <FontAwesomeIcon icon={["fab", "xing"]} />
            </a>
            {/*<a href="https://github.com/NJannasch" target="_blank" className="hover:text-blue-400 mr-4">
              <FontAwesomeIcon icon={["fas", "envelope"]} />
            </a>
            */}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center text-gray-600 p-1">
        <div className="text-base m-auto">
          © {new Date().getFullYear()} NJannasch.Dev
        </div>
        <div className="m-auto text-center">
            <Link className="px-1" to="/imprint">Imprint</Link>
            <Link className="px-1" to="/dataprivacy">Data Privacy</Link>
        </div>
        <div className="text-sm m-auto">
          Made with <FontAwesomeIcon icon={["fas", "heart"]} style={{color: "#000000"}} /> in Dortmund
        </div>
        <div className="text-xs m-auto">
          Built with{` `}<a href="https://www.gatsbyjs.org" target="_blank" rel="noreferrer">Gatsby</a>
        </div>
      </div>
  </footer>
)

export default Footer
